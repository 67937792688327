import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import CascaderItem from "./cascaderItem.vue";
// import clickOutside from "./../directives/clickOutside";
export default {
  name: "Cascader",
  // directives: { // 在当前组件上注册clickOutside指令
  //     clickOutside
  // },
  props: ["options", "defaultValue", "type"],
  //定义一个options属性用于接收外部传递给级联组件的数据，即选择项列表
  data: function data() {
    return {
      isVisible: false,
      selectedItems: [],
      // 用户已选择项
      resultPathTxt: "",
      addressInfo: [],
      flag: true,
      isValue: false
    };
  },
  mounted: function mounted() {
    // this.resultPathTxt = this.defaultValue;
  },
  components: {
    CascaderItem: CascaderItem
  },
  computed: {
    resultPath: function resultPath() {
      if (this.defaultValue) {
        return this.defaultValue;
      } else {
        // 通过用户已选择项计算出用户的选择路径
        return this.selectedItems.map(function (item) {
          return item.label;
        }).join("/");
      }
    }
  },
  watch: {
    resultPath: {
      deep: true,
      handler: function handler() {
        this.resultPathTxt = this.defaultValue;
        this.isValue = false;
      }
    }
  },
  methods: {
    onBlur: function onBlur() {
      var _this = this;
      if (this.resultPathTxt === "") {
        this.isValue = true;
      } else if (this.defaultValue != "") {
        this.isValue = false;
      }
      setTimeout(function () {
        if (_this.flag) {
          _this.isVisible = false;
        }
      }, 200);
    },
    onClear: function onClear() {
      //this.resultPathTxt="省/市/县";
    },
    close: function close() {
      // 关闭下半部分(选择列表部分)
      this.isVisible = false;
    },
    toggle: function toggle() {
      // 下半部分(选择列表部分)显示和隐藏的切换
      this.isVisible = !this.isVisible;
    },
    //判断指定元素在数组中的索引
    getIndex: function getIndex(arr, key) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][key]) {
          return i;
        }
      }
      return -1;
    },
    change: function change(newValue) {
      this.flag = false;
      this.selectedItems.splice(newValue.level, 1, newValue.item); // 替换当前点击位置信息
      this.selectedItems.splice(newValue.level + 1); // 删除当前点击位置之后的数据
      if (newValue.level === 0) {
        this.flag = false;
        var idx = this.getIndex(this.addressInfo, "province");
        if (idx !== -1) {
          this.addressInfo.splice(idx, 1);
        }
        this.addressInfo.push({
          province: newValue.item.value,
          provinceName: newValue.item.label
        });
      }
      if (newValue.level === 1) {
        this.flag = false;
        var _idx = this.getIndex(this.addressInfo, "city");
        if (_idx !== -1) {
          this.addressInfo.splice(_idx, 1);
        }
        this.addressInfo.push({
          city: newValue.item.value,
          cityName: newValue.item.label ? newValue.item.label : ' '
        });
      }
      if (newValue.level === 2) {
        this.flag = false;
        var _idx2 = this.getIndex(this.addressInfo, "district");
        if (_idx2 !== -1) {
          this.addressInfo.splice(_idx2, 1);
        }
        this.addressInfo.push({
          district: newValue.item.value,
          districtName: newValue.item.label
        });
      }
      if (!newValue.item.children) {
        this.$emit("addressdata", this.addressInfo);
        this.addressInfo = [];
        this.isVisible = false;
        this.flag = true;
      }
    }
  }
};