import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right-wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "t-content"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": _vm.handleSite
    }
  }, [_c('span', [_vm._v("新增收货地址")])]), (_vm.data.length == 0 ? false : true) ? _c('section', {
    staticClass: "form"
  }, [_c('div', [_c('table', {
    staticClass: "v2-table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.data, function (item) {
    return _c('tr', [_c('td', [_c('div', {
      staticClass: "ff"
    }, [_vm._v(_vm._s(item.consignee) + " "), _c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.defaultStatus === 1 ? true : false,
        expression: "item.defaultStatus === 1 ? true : false"
      }],
      attrs: {
        "src": require("@/assets-v2/images/centre.png"),
        "alt": ""
      }
    })])]), _c('td', [_vm._v(_vm._s(_vm._f("manage")("".concat(item.provinceName, "-"))) + _vm._s(_vm._f("manage")("".concat(item.cityName, "-"))) + _vm._s(item.districtName))]), _c('td', [_vm._v(_vm._s(_vm._f("ellipsis")(item.addreDetail)))]), _c('td', [_vm._v(_vm._s(item.consigneePhone))]), _c('td', {
      staticClass: "tr-op"
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: item.defaultStatus === 1 ? false : true,
        expression: "item.defaultStatus === 1 ? false : true"
      }],
      staticClass: "default",
      on: {
        "click": function click($event) {
          return _vm.handleDefault(item.id);
        }
      }
    }, [_vm._v("设为默认")]), _c('span', {
      staticClass: "compile",
      on: {
        "click": function click($event) {
          return _vm.handleDetail(item);
        }
      }
    }, [_vm._v("编辑")]), _c('span', {
      staticClass: "deletes",
      on: {
        "click": function click($event) {
          return _vm.deleteById(item.id);
        }
      }
    }, [_vm._v("删除")])])]);
  }), 0)])])]) : _vm._e()]), _c('Dialog', {
    attrs: {
      "title": _vm.title,
      "show": _vm.addressShow,
      "width": "700px",
      "height": "500px"
    },
    on: {
      "close": function close($event) {
        _vm.addressShow = false;
      }
    }
  }, [_c('div', {
    staticClass: "form-data",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', [_c('i', {
    staticStyle: {
      "color": "#FF2E2E",
      "padding-right": "10px"
    }
  }, [_vm._v("*")]), _vm._v("选择区县")]), _c('div', {
    staticClass: "control"
  }, [_c('Cascader', {
    ref: "isValue",
    staticStyle: {
      "float": "left"
    },
    attrs: {
      "options": _vm.options,
      "defaultValue": _vm.defaultValue
    },
    on: {
      "addressdata": _vm.getAddressdata
    }
  })], 1)]), _c('div', {
    staticClass: "field"
  }, [_c('label', [_c('i', {
    staticStyle: {
      "color": "#FF2E2E",
      "padding-right": "10px"
    }
  }, [_vm._v("*")]), _vm._v("详细地址")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.site,
      expression: "info.site",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isSite
    }, 'input'],
    attrs: {
      "placeholder": "详细地址",
      "pattern": "^[\\u0391-\\uFFE5]+$"
    },
    domProps: {
      "value": _vm.info.site
    },
    on: {
      "blur": [_vm.blurSite, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "site", $event.target.value.trim());
      }
    }
  })]), _vm.isSite ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("请输入详细地址")]) : _vm._e()]), _c('div', {
    staticClass: "field"
  }, [_c('label', [_c('i', {
    staticStyle: {
      "color": "#FF2E2E",
      "padding-right": "10px"
    }
  }, [_vm._v("*")]), _vm._v("收货人")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.consignee,
      expression: "info.consignee",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isConsignee
    }, 'input'],
    attrs: {
      "placeholder": "请输入",
      "pattern": "^[\\u0391-\\uFFE5]+$"
    },
    domProps: {
      "value": _vm.info.consignee
    },
    on: {
      "blur": [_vm.blurConsignee, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "consignee", $event.target.value.trim());
      }
    }
  })]), _vm.isConsignee ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("请输入收货人")]) : _vm._e()]), _c('div', {
    staticClass: "field"
  }, [_c('label', [_c('i', {
    staticStyle: {
      "color": "#FF2E2E",
      "padding-right": "10px"
    }
  }, [_vm._v("*")]), _vm._v("联系方式")]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.info.information,
      expression: "info.information",
      modifiers: {
        "trim": true
      }
    }],
    class: [{
      'is-danger': _vm.isPhone
    }, 'input'],
    attrs: {
      "placeholder": "请输入",
      "oninput": "value=value.replace(/[^\\d]/g,'')",
      "maxlength": "11",
      "pattern": "^(?:(?:\\+|00)86)?1[3-9]\\d{9}$"
    },
    domProps: {
      "value": _vm.info.information
    },
    on: {
      "blur": [_vm.blurPhone, function ($event) {
        return _vm.$forceUpdate();
      }],
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "information", $event.target.value.trim());
      }
    }
  })]), _vm.isPhone ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("请输入正确手机号码")]) : _vm._e()]), _c('div', {
    staticClass: "field"
  }, [_c('label'), _c('div', {
    staticClass: "field"
  }, [_c('CheckBox', {
    attrs: {
      "isCheck": _vm.checkboxCustom
    },
    on: {
      "click": function click($event) {
        _vm.checkboxCustom = !_vm.checkboxCustom;
      }
    }
  }, [_vm._v("设为默认收货地址")])], 1)]), _c('div', {
    staticClass: "field btn"
  }, [_c('button', {
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('button', {
    staticClass: "ok",
    on: {
      "click": _vm.handleSave
    }
  }, [_vm._v("确定")])])])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right-title"
  }, [_c('span', [_vm._v("收货地址管理")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("收件人")]), _c('th', [_vm._v("所在地")]), _c('th', [_vm._v("详细地址")]), _c('th', [_vm._v("电话")]), _c('th', [_vm._v("操作")])])]);
}];
export { render, staticRenderFns };